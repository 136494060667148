import React, { useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { Container } from "components/Container";
import { useCurrentUser } from "contexts/currentUser";
import { usePagination } from "contexts/pagination";
import {
  Offer,
  OfferSortFieldsEnum,
  OfferStatusEnum,
  SpecialCondition,
  useGetSpecialConditionsQuery,
  useOffersLazyQuery,
} from "generated/graphql";
import useFilters from "hooks/useFilters";
import { Filters } from "hooks/useFilters/types";
import useSorting from "hooks/useSorting";

import useOffersStore from "./OffersFooter/store";
import OffersTable from "./OffersTable";

const DEALERS_SURVEY_URL = process.env.REACT_APP_DEALERS_SURVEY_URL;

const Offers: React.FC = () => {
  const { paginationLimit } = usePagination();
  const { t } = useTranslation();

  const [, , { isAdmin }] = useCurrentUser();

  const setCountOffers = useOffersStore((state) => state.setCountOffers);

  const adminOnlyFilters = [
    Filters.TopOffer,
    Filters.MarketingOffer,
    Filters.IsPossibleToEnableNewConfigurator,
    Filters.IsNewConfiguratorEnabled,
  ];

  const filters = [
    Filters.Dealer,
    Filters.Vehicle,
    Filters.VehicleModel,
    Filters.OfferCustomerType,
    Filters.CreationType,
    Filters.Search,
    Filters.OnlyActive,
    ...(isAdmin ? adminOnlyFilters : []),
  ];

  const {
    renderFilters,
    dealerId,
    brandId,
    modelGroup,
    model,
    offerCustomerType,
    creationType,
    active,
    topOffer,
    marketingOffer,
    search,
    isPossibleToEnableNewConfigurator,
    isNewConfiguratorEnabled,
  } = useFilters(filters);

  const { data: specialConditionsData } = useGetSpecialConditionsQuery();

  const [listOffers, { data, loading }] = useOffersLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: (onCompletedData) => setCountOffers(onCompletedData?.countOffers || 0),
    errorPolicy: "all",
  });

  const { field, order } = useSorting({
    defaultField: OfferSortFieldsEnum.InsertedAt,
  });

  useEffect(() => {
    listOffers({
      variables: {
        search,
        pagination: {
          limit: paginationLimit,
          orderBy: field
            ? [{
              field: field as OfferSortFieldsEnum,
              order,
            }]
            : undefined,
        },
        filters: {
          dealerId,
          brandId,
          vehicleModelGroup: modelGroup,
          vehicleModel: model,
          creationType,
          customerType: offerCustomerType,
          status: active ? OfferStatusEnum.Live : active,
          topOffer,
          marketingOffer,
          isPossibleToEnableNewConfigurator,
          isNewConfiguratorEnabled:
            isNewConfiguratorEnabled || (isPossibleToEnableNewConfigurator ? false : undefined),
        },
      },
    });
  }, [
    isPossibleToEnableNewConfigurator,
    isNewConfiguratorEnabled,
    offerCustomerType,
    paginationLimit,
    marketingOffer,
    creationType,
    listOffers,
    modelGroup,
    topOffer,
    dealerId,
    brandId,
    active,
    search,
    model,
    order,
    field,
  ]);

  return (
    <VStack spacing={6}>
      {DEALERS_SURVEY_URL && (
        <Box
          w="full"
          borderRadius={8}
          background="white"
          px={6}
          py={3}
          h={16}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text
            fontSize={20}
            fontFamily="regular"
            fontWeight="bold"
            color="secondary.500"
          >
            {t("components.survey.your_opinion")}
          </Text>
          <Button
            onClick={() => window.open(DEALERS_SURVEY_URL, "_blank")}
            colorScheme="secondary"
            fontSize={14}
          >
            {t("components.survey.to_the_survey")}
          </Button>
        </Box>
      )}

      <Container
        alignCenter
        padding="0"
        paddingTop="0"
        paddingX="0"
        paddingBottom="0"
      >
        <Flex justifyContent="space-between" alignItems="center" padding="6">
          {renderFilters}
        </Flex>

        <OffersTable
          data={data?.offers as Offer[]}
          specialConditionsData={
            specialConditionsData?.specialConditions as SpecialCondition[]
          }
          loading={loading}
        />
      </Container>
    </VStack>
  );
};

export default Offers;
